/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";

const Headline = ({ link, os, fileName }) => {
  return (
    <Box>
      <Box>
        {/* <Typography variant="h3" gutterBottom>
          Empower Your Business with Seamless Inventory Management
        </Typography> */}
        <Typography variant="h3" color={"primary"} fontWeight={700}>
          Download now and make your hardware design processes more efficient
        </Typography>
        <Button
          sx={{ marginTop: 2 }}
          variant="contained"
          color="primary"
          component="a"
          href={link}
          size="large"
          download={fileName}
        >
          Download Now for {os}
        </Button>
      </Box>
    </Box>
  );
};

export default Headline;
