import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const mock = [
  {
    title: "What information do we collect?",
    description:
      "At Partix, we are committed to safeguarding your privacy and ensuring the security of your personal information. In the course of providing our services, we may collect and process the following types of information:",
  },
  {
    title: "Personal Information:",
    description:
      "This may include but is not limited to your name, email address, phone number, and other contact details. We collect this information when you sign up for our services, subscribe to newsletters, or engage with our website.",
  },
  {
    title: "Usage Data:",
    description:
      "We gather data about how you interact with our website and services. This may include information about your device, browser, and IP address. This data helps us improve our website, tailor our content to user preferences, and enhance the overall user experience.",
  },
  {
    title: "Cookies and Tracking Technologies:",
    description:
      "We use cookies and similar technologies to collect information about your browsing behavior. These tools help us analyze website traffic, customize content, and deliver targeted advertisements. You have the option to manage your cookie preferences through your browser settings.",
  },
  {
    title: "Payment Information:",
    description:
      "When you make a purchase, we may collect billing and payment information to process transactions securely.",
  },
  {
    title: "How We Use Your Information:",
    description:
      "We use the collected information for various purposes, including: Providing and improving our services. Personalizing your experience on our website. Communicating with you about updates, promotions, and important announcements. Ensuring the security and integrity of our services.",
  },
  {
    title: "Data Security:",
    description:
      "We prioritize the security of your information and employ industry-standard measures to protect against unauthorized access, disclosure, alteration, and destruction.",
  },
  {
    title: "Third-Party Services:",
    description:
      "Please note that certain third-party services integrated into our platform may have their own privacy policies, and we encourage you to review them.",
  },
  {
    title: "Your Consent:",
    description:
      "By using our website and services, you consent to the collection and use of your information as outlined in this policy.",
  },
];

const PrivacySection = ({ title, description }) => {
  return (
    <Box>
      <Typography
        variant={"h6"}
        gutterBottom
        sx={{
          fontWeight: "medium",
        }}
      >
        {title}
      </Typography>
      <Typography component={"p"} color={"text.secondary"}>
        {description}
      </Typography>
    </Box>
  );
};

PrivacySection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const Content = () => {
  return (
    <Box>
      {mock.map((item, i) => (
        <Box key={i} marginBottom={i < mock.length - 1 ? 4 : 0}>
          <PrivacySection {...item} />
        </Box>
      ))}
    </Box>
  );
};

export default Content;
