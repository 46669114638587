/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
//
import { db, auth, functions } from "../../../firebase";

const validationSchema = yup.object({
  password: yup
    .string()
    .required("Please enter your password")
    .min(12, "The password should have at minimum length of 12 characters")
    .matches(/[a-z]/, "The passwordmust have at least one lowercase character")
    .matches(/[A-Z]/, "The passwordmust have at least one uppercase character")
    .matches(/[0-9]+/, "The password must have at least one number")
    .matches(
      /[!@#$%^&*()\-_"=+]+/,
      "The password must have at least one special character"
    ),
  confirmPassword: yup
    .string()
    .required("Please enter your password again")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const PasswordResetForm = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const onSubmit = async (values, event) => {
    setLoading(true);
  
    // Extract the query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const actionCode = urlParams.get("oobCode") || '';
  
    try {
      // Verify the password reset code is valid.
      const email = await auth.verifyPasswordResetCode(actionCode);
  
      // Get the new password from the form values
      const newPassword = values.password;
  
      // Save the new password.
      await auth.confirmPasswordReset(actionCode, newPassword);
  
      // Password reset has been confirmed and new password updated.
      enqueueSnackbar("Password reset successfully", { variant: "success" });
      setLoading(false);
      navigate("/"); // Redirect to home page or login page
    } catch (error) {
      console.error("Error during password reset:", error);
      enqueueSnackbar(
        error.message || "Error during password reset. Please try again",
        { variant: "error" }
      );
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            mb: 2,
          }}
        >
          Reset account password
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Enter a new password
            </Typography>
            <TextField
              label="Password *"
              variant="outlined"
              name={"password"}
              type={"password"}
              fullWidth
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Confirm your new password
            </Typography>
            <TextField
              label="Confirm password *"
              variant="outlined"
              name={"confirmPassword"}
              type={"password"}
              fullWidth
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <LoadingButton
                loading={loading}
                size={"large"}
                variant={"contained"}
                type={"submit"}
              >
                Reset password
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </form>
      {/* <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar> */}
    </Box>
  );
};

export default PasswordResetForm;