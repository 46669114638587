import { auth, db } from "../firebase";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

export const getPremiumStatus = async () => {
  const userId = auth.currentUser?.uid;
  if (!userId) throw new Error("User not logged in");
  const subscriptionsRef = collection(db, "customers", userId, "subscriptions");
  const q = query(
    subscriptionsRef,
    where("status", "in", ["active", "trialing"])
  );

  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        // In this implementation we only expect one active or trialing subscription to exist.

        if (snapshot.docs.length === 0) {
          resolve({
            subscriptionData: null,
            status: false,
          });
        } else {
          resolve({
            subscriptionData: snapshot.docs[0].data(),
            status: true,
          });
        }
        unsubscribe();
      },
      reject
    );
  });
};
