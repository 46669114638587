export default function generateUidNumber(length) {
  if (!length) return;
  if (typeof length !== "number") return;
  if (length < 0) return;
  if (length % 1 !== 0) return;
  if (length === 0) return;
  if (length > 10) return;
  const salt = Math.floor(Math.random() * Date.now());
  const date = Date.now();
  const uid = String(date) + String(salt);
  const mid = parseInt(uid.length / 2);
  return uid.slice(mid, mid + length);
}
