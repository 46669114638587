import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import Main from "../../layouts/Main";
import Container from "components/Container";

import { Newsletter, Partners } from "./components";
import { useNavigate } from "react-router";
import { onAuthStateChanged } from "@firebase/auth";
import { auth, db } from "../../firebase";
import { useSearchParams } from "react-router-dom/dist";
import { LoadingButton } from "@mui/lab";
const OrderComplete = () => {
  const [invoiceUrl, setInvoiceUrl] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  onAuthStateChanged(auth, (user) => {
    db.collection("customers")
      .doc(user.uid)
      .collection("subscriptions")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().metadata.generatedUID === searchParams.get("uid")) {
            db.collection("customers")
              .doc(user.uid)
              .collection("subscriptions")
              .doc(doc.id)
              .collection("invoices")
              .get()
              .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  setInvoiceUrl(doc.data().hosted_invoice_url);
                });
              });
          }
        });
      });
  });

  return (
    <Main>
      <Container maxWidth={600}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component={"svg"}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={130}
            height={130}
            color={"success.light"}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
            />
          </Box>
          <Typography
            variant={"h4"}
            fontWeight={700}
            align={"center"}
            marginY={2}
          >
            Your order is completed!
          </Typography>
          <Typography
            fontWeight={400}
            color={"text.secondary"}
            align={"center"}
          >
            Thank you for your order!
            <br />
            Your subscription has been successfully processed.
            <br />
            You will receive an email confirmation when your order is completed.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 2,
              gap: 2,
            }}
          >
            <Button
              onClick={() => {
                navigate("/download");
              }}
              variant={"contained"}
              size={"large"}
              sx={{ marginTop: 4 }}
            >
              Download the app
            </Button>
            <Button
              disabled={!invoiceUrl}
              onClick={() => {
               window.open(invoiceUrl,"_blank");
              }}
              variant={"contained"}
              size={"large"}
              sx={{ marginTop: 4 }}
            >
              View Invoice
            </Button>
          </Box>
        </Box>
      </Container>
      {/* <Box bgcolor={"alternate.main"}>
        <Container>
          <Newsletter />
        </Container>
      </Box>
      <Container>
        <Partners />
      </Container> */}
    </Main>
  );
};

export default OrderComplete;
