import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Main from "layouts/Main";
import Container from "components/Container";
import { Contact, Headline, Partners, Stories } from "./components";
import { Button, Typography } from "@mui/material";

const Customers = () => {
  const [os, setOs] = React.useState("Unknown OS");
  const [link, setLink] = React.useState("");
  const [fileName, setFileName] = React.useState("");
  
  const linuxurl = process.env.REACT_APP_IMAGE_LINUX;
  const windowsurl = process.env.REACT_APP_IMAGE_WINDOWS;

  useEffect(() => {
    var OSName = "Unknown OS";
    if (navigator.userAgent.indexOf("Win") != -1) OSName = "Windows";
    if (navigator.userAgent.indexOf("Mac") != -1) OSName = "MacOS";
    if (navigator.userAgent.indexOf("X11") != -1) OSName = "UNIX";
    if (navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
    setOs(OSName);

    if (OSName === "Windows") {
      setLink({windowsurl});
      setFileName("Partix_Win_1.1.6.exe");
    } else if (OSName === "Linux") {
      setLink({linuxurl});
      setFileName("Partix_Linux_1.1.6.AppImage");
    } else if (OSName === "MacOS") {
      setLink(process.env.APP_IMAGE_MACOS);
      setFileName("{productName}_Mac_1.1.6.dmg");
    } else {
      setLink("#");
      setFileName("Unknown OS");
    }
  }, []);
  const theme = useTheme();
  return (
    <Main>
      <Box>
        <Box
          position={"relative"}
          sx={{
            backgroundColor: theme.palette.alternate.main,
            backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.main} 100%)`,
            marginTop: -13,
            paddingTop: 13,
          }}
        >
          <Container zIndex={3} position={"relative"}>
            <Container marginLeft={"0 !important"}>
              <Headline os={os} fileName={fileName} link={link} />
              <Box
                sx={{
                  marginTop: 4,
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    minWidth: 100,
                  }}
                >
                  <b
                    style={{
                      display: "inline-block",
                      minWidth: 100,
                    }}
                  >
                    Windows
                  </b>
                </Typography>
                <Typography>
                  <a href={windowsurl}>
                  Partix_Win_1.1.6.exe
                  </a>
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: 4,
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                }}
              >
                <Typography>
                  <b
                    style={{
                      display: "inline-block",
                      minWidth: 100,
                    }}
                  >
                    Linux
                  </b>
                </Typography>
                <Typography>
                  <a href="https://firebasestorage.googleapis.com/v0/b/inventory-777f7.appspot.com/o/app-versions%2Ftbd%20linux%20v1.0.0.zip?alt=media&token=824622dc-d0f8-48d1-9845-d0e4473a61f7">
                    Partix_Linux_v1.0.0.zip
                  </a>
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: 4,
                  display: "flex",
                  gap: 8,
                  alignItems: "center",
                }}
              >
                <Typography>
                  <b
                    style={{
                      display: "inline-block",
                      minWidth: 100,
                    }}
                  >
                    Mac OS
                  </b>
                </Typography>
                <Typography>
                  <a href="https://firebasestorage.googleapis.com/v0/b/inventory-777f7.appspot.com/o/app-versions%2Fupdate-soon.jpg?alt=media&token=e54e361e-ab84-4660-be3c-6c2cd562a8fc">
                    Partix_1.0.0-arm64.dmg
                  </a>
                </Typography>
              </Box>
            </Container>
          </Container>
          <Box
            component={"svg"}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            width={1}
            marginBottom={-1}
            position={"relative"}
            zIndex={2}
          >
            <path
              fill={theme.palette.background.paper}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Box>

        {/* <Box position={'relative'} zIndex={3} marginTop={{ xs: 0, md: -22 }}>
          <Container>
            <Partners />
          </Container>
        </Box> */}
        {/* <Container>
          <Stories />
        </Container>
        <Box bgcolor={theme.palette.alternate.main}>
          <Container>
            <Contact />
          </Container>
        </Box> */}
      </Box>
    </Main>
  );
};

export default Customers;
