// Should eventually merge this with the user signup form

/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { db, auth, functions } from "../../../../firebase";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
  lastName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your last name"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  companyName: yup
    .string()
    .required("Please specify your company name")
    .min(2, "Please enter your company name"),
  password: yup
    .string()
    .required("Please enter your password")
    .min(12, "The password should have at minimum length of 12 characters")
    .matches(/[a-z]/, "The passwordmust have at least one lowercase character")
    .matches(/[A-Z]/, "The passwordmust have at least one uppercase character")
    .matches(/[0-9]+/, "The password must have at least one number")
    .matches(
      /[!@#$%^&*()\-_"=+]+/,
      "The password must have at least one special character"
    ),
  confirmPassword: yup
    .string()
    .required("Please enter your password again")
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

const UserForm = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [btnLoading, setBtnLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    password: "",
    confirmPassword: "",
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // SUbmit the form
  const onSubmit = async (values) => {
    setBtnLoading(true);

    // Extract the query parameters
    const urlParams = new URLSearchParams(window.location.search);
    const urlData = urlParams.get("data");
    const parts = urlData.split("&");
    const invitationUUID = parts[0];
    const companyUID = parts[1];
    const expiry = parts[2];

    // Prepare the data payload
    const data = {
      email: values.email,
      password: values.password,
      companyName: values.companyName,
      firstName: values.firstName,
      lastName: values.lastName,
      encrCompanyUID: companyUID,
      encrInvitationUUID: invitationUUID,
      userRole: "superAdmin",
    };

    // Call the cloud function
    const registerAdmin = functions.httpsCallable("adminSignUp");

    try {
      const result = await registerAdmin(data);
      console.log(result.data.message);
      if (result.data.status == "success") {
        // setSnackbarMessage("Admin SignUp Success!");
        enqueueSnackbar("Sign Up Success", { variant: 'success' });
        // setSnackbarSeverity("success");
        setSnackbarOpen(true);
        setBtnLoading(false);
        navigate("/downloada29ffb3d35e64707749677938f25280bd62edd62f10a310459d6dbd12a711f37");
      } else {
        // setSnackbarMessage(
        //   "Error signing up the Admin : " + result.data.message
        // );
        enqueueSnackbar(result.data.message, { variant: 'error' });
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setBtnLoading(false);
      }
    } catch (error) {
      // setSnackbarMessage("Error signing up : " + error.message);
      enqueueSnackbar(error.message, { variant: 'error' });
      // setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setBtnLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => onSubmit(values),
  });

  useEffect(() => {
    const fetchDetails = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const data = urlParams.get("data");
      const parts = data.split("&");
      const invitationUUID = parts[0];
      const companyUID = parts[1];
      const expiry = parts[2];

      if (invitationUUID && companyUID) {
        const fetchAdminDetails = functions.httpsCallable(
          "adminSignUpAutofill"
        );

        try {
          const result = await fetchAdminDetails({
            hashedData: encodeURIComponent(
              `${invitationUUID}&${companyUID}&${expiry}`
            ),
          });
          console.log(result.data);
          const { exists, userDetails } = result.data;

          if (exists) {
            formik.setFieldValue("companyName", userDetails.companyName);
            formik.setFieldValue("email", userDetails.email);
            formik.setFieldValue("firstName", userDetails.firstName);
            formik.setFieldValue("lastName", userDetails.lastName);
            setInitialLoading(false);
          } else {
            console.log("No data exists for the provided IDs.");
            setInitialLoading(false);
            navigate("/");
          }
        } catch (error) {
          console.error("Error fetching user details:", error.message);
          setInitialLoading(false);
          navigate("/");
        }
      } else {
        setInitialLoading(false);
        navigate("/");
      }
    };
    fetchDetails();
  }, [navigate]);

  return (
    <Box>
      {initialLoading || !formik.values.companyName ? (
        <CircularProgress />
      ) : (
        <>
          <Box marginBottom={4}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
                mb: 2,
              }}
            >
               Create an account for {formik.values.companyName}
            </Typography>
            <Typography color="text.secondary">
              Fill out the form to get started.
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
                  Enter your first name
                </Typography>
                <TextField
                  label="First name *"
                  variant="outlined"
                  name={"firstName"}
                  fullWidth
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
                  Enter your last name
                </Typography>
                <TextField
                  label="Last name *"
                  variant="outlined"
                  name={"lastName"}
                  fullWidth
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
                  Enter your company email
                </Typography>
                <TextField
                  label="Email *"
                  variant="outlined"
                  name={"email"}
                  fullWidth
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
                  Enter your company name
                </Typography>
                <TextField
                  label="Company name *"
                  variant="outlined"
                  name={"companyName"}
                  fullWidth
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                  helperText={
                    formik.touched.companyName && formik.errors.companyName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
                  Enter a password
                </Typography>
                <TextField
                  label="Password *"
                  variant="outlined"
                  name={"password"}
                  type={"password"}
                  fullWidth
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
                  Confirm your password
                </Typography>
                <TextField
                  label="Confirm password *"
                  variant="outlined"
                  name={"confirmPassword"}
                  type={"password"}
                  fullWidth
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                />
              </Grid>
              <Grid item container xs={12}>
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "stretched", sm: "center" }}
                  justifyContent={"space-between"}
                  width={1}
                  maxWidth={600}
                  margin={"0 auto"}
                >
                  <Box marginBottom={{ xs: 1, sm: 0 }}>
                    <Typography variant={"subtitle2"}>
                      Already have an account?{" "}
                      <Link
                        component={"a"}
                        color={"primary"}
                        href={"/usersignin"}
                        underline={"none"}
                      >
                        Login.
                      </Link>
                    </Typography>
                  </Box>
                  <LoadingButton
                    loading={btnLoading}
                    size={"large"}
                    variant={"contained"}
                    type={"submit"}
                  >
                    Create account
                  </LoadingButton>
                </Box>
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Typography
                  variant={"subtitle2"}
                  color={"text.secondary"}
                  align={"center"}
                >
                  By clicking "Sign up" button you agree with our{" "}
                  <Link
                    component={"a"}
                    color={"primary"}
                    href={"/agreement.pdf"}
                    underline={"none"}
                  >
                    company terms and conditions.
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </form>
          {/* <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar> */}
        </>
      )}
    </Box>
  );
};

export default UserForm;
