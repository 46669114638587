import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Container from "components/Container";
import Main from "../../layouts/Main";

import { useNavigate } from "react-router-dom";

const ContactSuccess= () => {

  const navigate = useNavigate();

  return (
    <Main>
      <Container maxWidth={600}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: 'center', // Centers vertically
            height: '60vh', // Viewport height
            pt: 1 // Adjust top padding if needed
          }}
        >
          <Box
            component={"svg"}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            width={100}
            height={100}
            color={"success.light"}
            
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
            />
          </Box>
          <Typography
            variant={"h4"}
            fontWeight={700}
            align={"center"}
            marginY={2}
          >
            Support Request Successfully Submitted!
          </Typography>
          <Typography
            fontWeight={400}
            color={"text.secondary"}
            align={"center"}
            mb={4}
          >
            Thank you for reaching out to us. We're on it and will get back to you within one business day.
          </Typography>

          <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => navigate("/")}
          sx={{ mt: 2}}
        >
          Go to Home
        </Button>

        </Box>
      </Container>
    </Main>
  );
};

export default ContactSuccess;
