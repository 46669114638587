import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

const firebaseConfig = {
    // Partix production
    apiKey: "AIzaSyBZrhiPdvrhzAzqEGJHM9pfSQBGfNvGvrU",
    authDomain: "partix-production.firebaseapp.com",
    projectId: "partix-production",
    storageBucket: "partix-production.appspot.com",
    messagingSenderId: "675519769982",
    appId: "1:675519769982:web:61e898e225e6451539d72f",
    measurementId: "G-CHRWCC979H"

    // apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    // authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    // projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_FIREBASE_APP_ID,
    // measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

    // apiKey: "AIzaSyC2mKMqwSisqH8mivPYLkkMQWe91bijbk8",
    // authDomain: "inventory-777f7.firebaseapp.com",
    // projectId: "inventory-777f7",
    // storageBucket: "inventory-777f7.appspot.com",
    // messagingSenderId: "200564121",
    // appId: "1:200564121:web:beb40bb3e8698799f40708",
    // measurementId: "G-JG7LP0TR6M"
}

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const app = firebase.app();
export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();
