import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import Page from "../components/Page";
import Main from "layouts/Main";
import { auth, db } from "../../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { CircularProgress } from "@mui/material";
import { getPremiumStatus } from "util/checkPremiumStatus";
import { getPortalUrl } from "util/stripePayment";

const validationSchema = yup.object({
  fullName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  bio: yup.string().trim().max(500, "Should be less than 500 chars"),
  country: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(80, "Please enter a valid name")
    .required("Please specify your country name"),
  city: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(80, "Please enter a valid name")
    .required("Please specify your city name"),
  address: yup
    .string()
    .required("Please specify your address")
    .min(2, "Please enter a valid address")
    .max(200, "Please enter a valid address"),
});

const General = () => {
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    engineerType: "",
    userRole: "",
  };

  const onSubmit = (values) => {
    return values;
  };

  const [initialLoading, setInitialLoading] = useState(true);
  const [checkoutUrl, setCheckoutUrl] = useState("");
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      db.collection("usersCompany")
        .doc(user.uid)
        .onSnapshot((doc) => {
          const companyUID = doc.data().companyUID;
          db.collection("companyDB")
            .doc(companyUID)
            .collection("userProfiles")
            .doc(user.uid)
            .get()
            .then((doc) => {
              console.log(doc.data());
              formik.setFieldValue("firstName", doc.data().firstName);
              formik.setFieldValue("lastName", doc.data().lastName);
              formik.setFieldValue("email", doc.data().email);
              formik.setFieldValue("companyName", doc.data().companyName);
              formik.setFieldValue("engineerType", doc.data().engineerType);
              formik.setFieldValue("userRole", doc.data().userRole);
              setInitialLoading(false);
            });
        });
    });
  }, []);
  return (
    <Main>
      <Page>
        {initialLoading ? (
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Typography variant="h6" gutterBottom fontWeight={700}>
              View your private information
            </Typography>

            <Box paddingY={4}>
              <Divider />
            </Box>
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant={"subtitle2"}
                    sx={{ marginBottom: 2 }}
                    fontWeight={700}
                  >
                    First Name
                  </Typography>
                  <TextField
                    disabled
                    label="First name *"
                    variant="outlined"
                    name={"firstName"}
                    fullWidth
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.fullName && Boolean(formik.errors.fullName)
                    }
                    helperText={
                      formik.touched.fullName && formik.errors.fullName
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant={"subtitle2"}
                    sx={{ marginBottom: 2 }}
                    fontWeight={700}
                  >
                    Last Name
                  </Typography>
                  <TextField
                    disabled
                    label="Last Name"
                    variant="outlined"
                    name={"lastName"}
                    fullWidth
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant={"subtitle2"}
                    sx={{ marginBottom: 2 }}
                    fontWeight={700}
                  >
                    Email
                  </Typography>
                  <TextField
                    disabled
                    label="Email"
                    variant="outlined"
                    name={"email"}
                    fullWidth
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    // error={
                    //   formik.touched.country && Boolean(formik.errors.country)
                    // }
                    // helperText={formik.touched.country && formik.errors.country}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant={"subtitle2"}
                    sx={{ marginBottom: 2 }}
                    fontWeight={700}
                  >
                    Company Name
                  </Typography>
                  <TextField
                    disabled
                    label="Company Name"
                    variant="outlined"
                    name={"companyName"}
                    fullWidth
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant={"subtitle2"}
                    sx={{ marginBottom: 2 }}
                    fontWeight={700}
                  >
                    Engineer Type
                  </Typography>
                  <TextField
                    disabled
                    label="Engineer Type"
                    variant="outlined"
                    name={"engineerType"}
                    fullWidth
                    value={formik.values.engineerType}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant={"subtitle2"}
                    sx={{ marginBottom: 2 }}
                    fontWeight={700}
                  >
                    User Role
                  </Typography>
                  <TextField
                    disabled
                    label="User Role"
                    variant="outlined"
                    name={"userRole"}
                    fullWidth
                    value={formik.values.userRole}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>
              </Grid>
            </form>
          </Box>
        )}
      </Page>
    </Main>
  );
};

export default General;
