import { db, auth, app } from "../firebase";
import { FirebaseApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  addDoc,
  collection,
  getFirestore,
  onSnapshot,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import generateUidNumber from "./generateUidNumber";

export const getCheckoutUrl = async (priceId) => {
  return new Promise(async (resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      const userId = user?.uid;
      if (!userId) {
        reject({
          error: "User not logged in",
          code: "noauth",
        });
        return;
      }

      const checkoutSessionRef = collection(
        db,
        "customers",
        userId,
        "checkout_sessions"
      );
      db.collection("usersCompany")
        .doc(userId)
        .get()
        .then(async (doc) => {
          let uidNumber = generateUidNumber(6);
          const docRef = await addDoc(checkoutSessionRef, {
            price: priceId,
            success_url: `${window.location.origin}/success?uid=${uidNumber}`,
            cancel_url: window.location.origin,
            metadata: {
              companyUID: doc.data().companyUID,
              generatedUID: uidNumber,
            },
          });
          const unsubscribe = onSnapshot(docRef, (snap) => {
            const { error, url } = snap.data();
            if (error) {
              unsubscribe();
              reject(new Error(`An error occurred: ${error.message}`));
            }
            if (url) {
              console.log("Stripe Checkout URL:", url);
              unsubscribe();
              resolve(url);
            }
          });
        });
    });
  });
};

export const getPortalUrl = async () => {
  const user = auth.currentUser;
  let dataWithUrl;
  try {
    const functions = getFunctions(app, "us-east1");
    const functionRef = httpsCallable(
      functions,
      "ext-firestore-stripe-payments-createPortalLink"
    );
    const { data } = await functionRef({
      customerId: user?.uid,
      returnUrl: window.location.origin,
    });
    // Add a type to the data
    dataWithUrl = data;
    console.log("dataWithUrl: ", dataWithUrl);
    console.log("Reroute to Stripe portal: ", dataWithUrl?.url);
  } catch (error) {
    console.error(error);
  }
  return new Promise((resolve, reject) => {
    if (dataWithUrl?.url) {
      resolve(dataWithUrl?.url);
    } else {
      reject(new Error("No url returned"));
    }
  });
};
