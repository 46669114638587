import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Main from "layouts/Main";
import Container from "components/Container";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../firebase";
import { PasswordResetForm } from "./components";

const PasswordReset = () => {
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/");
      }
    });
  }, [navigate]);

  return (
    <Main>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: 'calc(100vh - 247px)',
          justifyContent: 'center',
          py: 2, // Add some vertical padding
        }}
      >
        <Container maxWidth="sm">
          <PasswordResetForm />
        </Container>
      </Box>
    </Main>
  );
};

export default PasswordReset;