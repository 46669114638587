import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";

import { onAuthStateChanged } from "firebase/auth";
import Main from "layouts/Main";
import { getPremiumStatus } from "util/checkPremiumStatus";
import { getPortalUrl } from "util/stripePayment";
import Page from "../components/Page";
import { auth, db } from "../../../firebase";
import { Button, CircularProgress } from "@mui/material";
const Billing = () => {
  const [checkoutUrl, setCheckoutUrl] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    }
  });
  useEffect(() => {
    async function checkPremiuim() {
      let subscription = await getPremiumStatus();
      if (subscription.status) {
        let data = {
          subscriptionStart: subscription.subscriptionData.current_period_start,
          subscriptionEnd: subscription.subscriptionData.current_period_end,
          name: subscription.subscriptionData.items[0].price.product.name,
        };

        setSubscriptionData(data);
        getPortalUrl().then((url) => {
          setCheckoutUrl(url);
          setInitialLoading(false);
        });
      }
    }
    if (user) checkPremiuim();
  }, [user]);

  return (
    <Main>
      <Page>
        {initialLoading ? (
          <Box
            sx={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Typography variant="h5" gutterBottom fontWeight={700}>
              View Subscription Details
            </Typography>

            <Box paddingY={4}>
              <Divider />
            </Box>

            <Box>
              <Typography variant="body1" gutterBottom fontWeight={700}>
                Subscription Start :{" "}
                {subscriptionData.subscriptionStart.toDate().toDateString()}
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight={700}>
                Subscription End :{" "}
                {subscriptionData.subscriptionEnd.toDate().toDateString()}
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight={700}>
                Subscription Name : {subscriptionData.name}
              </Typography>
            </Box>
            <Button
              href={checkoutUrl}
              target="_blank"
              color="primary"
              variant="contained"
              sx={{
                mt: 2,
              }}
            >
              Manage Subscription & Invoices
            </Button>
          </Box>
        )}
      </Page>
    </Main>
  );
};

export default Billing;
