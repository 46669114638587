import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { SnackbarProvider } from "notistack";

// import Navbar from './components/Navbar';
// import CompanySignupPage from './views/CompanySignup';
// import FirstAdminSignupPage from './views/FirstAdminSignup';
// import Container from './components/Container';
import Page from "./components/Page";
// import SuccessPage from './views/SuccessPage';
// import NewUserSignUp from './views/NewUserSignUp';
// import HomeView  from "./views/Home";
import IndexView from "./views/IndexView/IndexView";
import PricingView from "./views/Pricing/Pricing";
import CompanySignUp from "./views/SignupSimple/CompanySignupSimple";
import UserSignup from "./views/SignupSimple/UserSignupSimple";
import SuperSignup from "./views/SignupSimple/SuperSignupSimple";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./firebase";
// Adding from the template
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import CompanyTerms from "views/CompanyTerms";
import SigninSimple from "views/SigninSimple";
import { createContext, useContext } from "react";
import Download from "views/Download";
import OrderComplete from "views/OrderComplete";
import EmailSentSuccess from "views/EmailSentSuccess/EmailSentSuccess";
import { Billing, General } from "views/Account";
import { getPremiumStatus } from "util/checkPremiumStatus";
import { getPortalUrl } from "util/stripePayment";
import ContactPage from "views/ContactPageCover";
import ContactSuccess from "views/ContactSuccess";
import TrialSignupSimple from "views/SignupSimple/FreeTrialSignupSimple";
import WaitListSuccess from "views/EmailSentSuccess/WaitlistSuccess";
import PasswordReset from "views/ActionHandlers/PasswordReset";

export const AuthContext = createContext(null);

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-JG7LP0TR6M', {
        page_path: location.pathname,
      });
    }
  }, [location.pathname]);
}

function App() {
  const [user, setUser] = useState(null);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
      db.collection("usersCompany")
        .doc(uid)
        .get()
        .then((doc) => {
          user.CompanyUID = doc.data().companyUID;
          setUser(user);
        });
    }
  });

  return (
    <>
      <Router>
        <div>
          <SnackbarProvider >
          <Page>
            <Routes>
              <Route path="/" element={<IndexView />} />
              <Route path="/pricing" element={<PricingView />} />
              <Route path="/trialsignup" element={<TrialSignupSimple />} />
              <Route path="/companysignup" element={<CompanySignUp />} />
              <Route path="/usersignup" element={<UserSignup />} />
              {/* <Route path="/usersignin" element={<SigninSimple />} /> */}
              <Route path="/superadminsignup" element={<SuperSignup />} />
              <Route path="/company-terms" element={<CompanyTerms />} />
              <Route path="/downloada29ffb3d35e64707749677938f25280bd62edd62f10a310459d6dbd12a711f37" element={<Download />} />
              <Route path="/success" element={<OrderComplete />} />
              <Route path="/email-success" element={<EmailSentSuccess />} />
              <Route path="/addedtowaitlist" element={<WaitListSuccess />} />
              <Route path="/account" element={<General />} />
              <Route path="/subscription" element={<Billing />} />
              <Route path="/contactus" element={<ContactPage />} />
              <Route path="/contact-success" element={<ContactSuccess />} />
              <Route path="/passwordreset" element={<PasswordReset />} />
              {/* <Route path='/newusersignup' element={<NewUserSignUp /> } /> */}
            </Routes>
          </Page>
          </SnackbarProvider>
        </div>
      </Router>
    </>
  );
}

export default App;
