import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { functions } from "../../../../firebase";

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your first name"),
  lastName: yup
    .string()
    .trim()
    .min(2, "Please enter a valid name")
    .max(50, "Please enter a valid name")
    .required("Please specify your last name"),
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .required("Email is required."),
  companyName: yup
    .string()
    .required("Please specify your company name")
    .min(2, "Please enter your company name"),
});

const CompanyForm = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [loading, setLoading] = useState(false);
  const [demoScheduled, setDemoScheduled] = useState(false);

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      console.log("Event scheduled:", e.data.payload);
      setDemoScheduled(true);
      setSnackbarMessage("Demo successfully scheduled!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    },
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const onSubmit = async (values) => {
    if (!demoScheduled) {
      setSnackbarMessage("Please schedule a demo before submitting.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    const { email, companyName, firstName, lastName } = values;
    const onSubmitFunction = functions.httpsCallable("partixWaitlistForm");

    try {
      const result = await onSubmitFunction({
        email: email,
        companyName: companyName,
        firstName: firstName,
        lastName: lastName,
      });
      setSnackbarMessage(result.data.message);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage("Error adding document : " + error.message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      companyName: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography variant="h4" sx={{ fontWeight: 700, marginBottom: 4 }}>
          Join the Partix Early Adopters
        </Typography>
        <Typography color="text.secondary">
          Fill out the form and schedule your demo to get started.
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Company Email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="companyName"
              name="companyName"
              label="Company Name"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={
                formik.touched.companyName && Boolean(formik.errors.companyName)
              }
              helperText={
                formik.touched.companyName && formik.errors.companyName
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={"subtitle2"} sx={{ marginBottom: 2 }}>
              Schedule your demo
            </Typography>
            <Box
              sx={{
                border: "1px solid #e0e0e0",
                borderRadius: "4px",
                padding: "4px",
                backgroundColor: "#f5f5f5",
                height: {
                  xs: "600px", // Smaller height on extra-small screens
                  sm: "800px", // Medium height on small screens
                  md: "500px", // Full height on medium screens and above
                },
                overflow: "hidden",
              }}
            >
              <InlineWidget
                url="https://calendly.com/anwar-partix"
                styles={{
                  height: "100%", // This will make it fill the container
                  border: "none",
                }}
                prefill={{
                  email: formik.values.email,
                  firstName: formik.values.firstName,
                  lastName: formik.values.lastName,
                  name: `${formik.values.firstName} ${formik.values.lastName}`,
                }}
              />
            </Box>
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: "column", sm: "row" }}
              alignItems={{ xs: "stretched", sm: "center" }}
              justifyContent={"space-between"}
              width={1}
              maxWidth={600}
              margin={"0 auto"}
            >
              <LoadingButton
                loading={loading}
                size={"large"}
                variant={"contained"}
                type={"submit"}
                disabled={!demoScheduled || !formik.isValid}
              >
                {demoScheduled
                  ? "Complete Sign Up"
                  : "Schedule Demo to Sign Up"}
              </LoadingButton>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography
              variant={"subtitle2"}
              color={"text.secondary"}
              align={"center"}
            >
              By clicking "Sign up" button you agree with our{" "}
              <Link
                component={"a"}
                color={"primary"}
                href={"/agreement.pdf"}
                underline={"none"}
                target={"_blank"}
              >
                company terms and conditions.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CompanyForm;
