import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { db, functions } from "../../../../firebase";

const Hero = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const [spotsLeft, setSpotsLeft] = useState(null);

  useEffect(() => {
    const fetchSpotsLeft = async () => {
      const fetchSpotsLeftFunc = functions.httpsCallable("fetchWaitlistLength");
      try {
        const result = await fetchSpotsLeftFunc();
        setSpotsLeft(result.data.spotsLeft);
      } catch (error) {
        console.error("Error fetching spots left: ", error);
      }
    };

    fetchSpotsLeft();
  }, []);

  return (
    <Box paddingY={{ xs: 0, sm: "4rem", md: "6rem" }}>
      <Container>
        <Grid container spacing={4} alignItems="center">
          {/* Text Content */}
          <Grid item xs={12} md={6}>
            <Typography
              variant="h3"
              color="text.primary"
              gutterBottom
              sx={{ fontWeight: 600 }}
            >
              Accelerate your hardware design with
              <br />
              <Typography
                color={"primary"}
                component={"span"}
                variant={"inherit"}
                sx={{
                  background: `linear-gradient(180deg, transparent 82%, ${theme.palette.secondary.main} 0%)`,
                }}
              >
                the smart purchasing and inventory tool
              </Typography>
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              sx={{ fontWeight: 400, mt: 6 }}
            >
              Built for small and medium-sized hardware engineering companies.
              Our platform simplifies and reduces repetitive tasks in hardware
              development.
            </Typography>
            {/* <Typography
              variant="button"
              component="p"
              color="text.secondary"
              sx={{ fontWeight: 400, mt: 2 }}
            >
              <strong>Exclusive Offer: Join our early adopters!</strong>
              <br />
              We are offering a 6-month free trial for the first 10 companies signing up. The software is available for download now.
            </Typography> */}
            <Button
              component={"a"}
              variant="contained"
              color="primary"
              size="large"
              fullWidth={!isMd}
              href={"/trialsignup"}
              sx={{ mt: 4 }}
            >
              Schedule Demo!
            </Button>
            {/* <Typography
              variant="body1"
              component="p"
              color="text.secondary"
              sx={{ fontWeight: 400, mt: 1 }}
            >
              {spotsLeft !== null ? `${spotsLeft} spots left!` : 'Loading...'}
            </Typography> */}
          </Grid>

          {/* Image */}
          <Grid item xs={12} md={6}>
            <Box
              component={"img"}
              height={1}
              width={1}
              src={
                "https://assets.maccarianagency.com/screenshots/dashboard.png"
              }
              alt="..."
              boxShadow={3}
              borderRadius={2}
              maxWidth={600}
              sx={{
                filter:
                  theme.palette.mode === "dark" ? "brightness(0.7)" : "none",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Hero;
